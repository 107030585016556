const QUERY_KEYS = {
  moims: 'moims',
  myMoims: 'myMoims',
  myZzimMoims: 'myZzimMoims',
  moim: 'moim',
  myMoim: 'myMoim',
  userKey: 'userKey',
  chat: 'chat',
  chatPreview: 'chatPreview',
  chamyoMine: 'chamyoMine',
  ZzimMine: 'ZzimMine',
  chamyoAll: 'chamyoAll',
  comment: 'comment',
  myInfo: 'myinfo',
  please: 'please',
  pleases: 'pleases',
  notifications: 'notifications',
  darakbang: 'darakbang',
  myDarakbangs: 'myDarakbangs',
  myRoleInDarakbang: 'myRoleInDarakbang',
  darakbangMembers: 'darakbangMembers',
  darakbangInviteCode: 'darakbangInviteCode',
  darakbangNameByCode: 'darakbangNameByCode',
};

export default QUERY_KEYS;
