export default function MainLogoIcon() {
  return (
    <svg
      width="97"
      height="156"
      viewBox="0 0 97 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M56.4298 85.6189C66.9193 92.3705 77.9332 91.7543 85.0777 86.0022C92.2965 80.1902 94.6455 69.8559 89.1462 59.3933C86.6317 54.6094 87.2342 47.4805 90.0051 42.0344C95.3819 31.4664 91.4874 19.5015 84.0799 12.1448C76.5625 4.67898 64.6431 1.19775 53.5721 8.58763C49.417 11.3612 43.7212 11.9622 39.3887 9.96939C28.5079 4.96458 17.2717 6.89035 10.2447 13.8065C3.13222 20.8069 1.0445 32.1835 6.91682 44.5315C8.23293 47.2989 8.2367 50.6317 6.94422 53.5003C1.35104 65.9139 4.3409 77.481 12.1605 84.1794C19.9184 90.825 31.8191 92.1402 42.8663 85.5048C46.9545 83.0492 52.4931 83.085 56.4298 85.6189Z"
        fill="#43B75D"
        stroke="black"
        strokeWidth="7"
      />
      <path
        d="M56.4298 85.6189C66.9193 92.3705 77.9332 91.7543 85.0777 86.0022C92.2965 80.1902 94.6455 69.8559 89.1462 59.3933C86.6317 54.6094 87.2342 47.4805 90.0051 42.0344C95.3819 31.4664 91.4874 19.5015 84.0799 12.1448C76.5625 4.67898 64.6431 1.19775 53.5721 8.58763C49.417 11.3612 43.7212 11.9622 39.3887 9.96939C28.5079 4.96458 17.2717 6.89035 10.2447 13.8065C3.13222 20.8069 1.0445 32.1835 6.91682 44.5315C8.23293 47.2989 8.2367 50.6317 6.94422 53.5003C1.35104 65.9139 4.3409 77.481 12.1605 84.1794C19.9184 90.825 31.8191 92.1402 42.8663 85.5048C46.9545 83.0492 52.4931 83.085 56.4298 85.6189Z"
        fill="#43B75D"
        stroke="black"
        strokeWidth="7"
      />
      <path
        d="M22 42.5071C21.9998 41.6521 22.1861 40.8075 22.546 40.032C22.9058 39.2564 23.4305 38.5688 24.0834 38.0169L44.6535 20.3884C45.7143 19.4919 47.0583 19 48.4472 19C49.8361 19 51.1801 19.4919 52.2409 20.3884L72.8109 38.0169C73.4638 38.5688 73.9885 39.2564 74.3484 40.032C74.7082 40.8075 74.8945 41.6521 74.8943 42.5071V68.9542C74.8943 70.513 74.2751 72.0078 73.173 73.11C72.0708 74.2122 70.5759 74.8314 69.0172 74.8314H27.8771C26.3184 74.8314 24.8236 74.2122 23.7214 73.11C22.6192 72.0078 22 70.513 22 68.9542V42.5071Z"
        fill="white"
        stroke="black"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.2628 74.8316V51.323C57.2628 50.5436 56.9532 49.7962 56.4021 49.2451C55.851 48.694 55.1036 48.3844 54.3242 48.3844H42.5699C41.7906 48.3844 41.0431 48.694 40.492 49.2451C39.9409 49.7962 39.6313 50.5436 39.6313 51.323V74.8316"
        fill="white"
      />
      <path
        d="M57.2628 74.8316V51.323C57.2628 50.5436 56.9532 49.7962 56.4021 49.2451C55.851 48.694 55.1036 48.3844 54.3242 48.3844H42.5699C41.7906 48.3844 41.0431 48.694 40.492 49.2451C39.9409 49.7962 39.6313 50.5436 39.6313 51.323V74.8316"
        stroke="black"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="39"
        y1="74.5"
        x2="56"
        y2="74.5"
        stroke="black"
        strokeWidth="7"
      />
      <path
        d="M22 42.5071C21.9998 41.6521 22.1861 40.8075 22.546 40.032C22.9058 39.2564 23.4305 38.5688 24.0834 38.0169L44.6535 20.3884C45.7143 19.4919 47.0583 19 48.4472 19C49.8361 19 51.1801 19.4919 52.2409 20.3884L72.8109 38.0169C73.4638 38.5688 73.9885 39.2564 74.3484 40.032C74.7082 40.8075 74.8945 41.6521 74.8943 42.5071V68.9542C74.8943 70.513 74.2751 72.0078 73.173 73.11C72.0708 74.2122 70.5759 74.8314 69.0172 74.8314H27.8771C26.3184 74.8314 24.8236 74.2122 23.7214 73.11C22.6192 72.0078 22 70.513 22 68.9542V42.5071Z"
        fill="#FF7700"
        stroke="black"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M57.2628 74.8316V51.323C57.2628 50.5436 56.9532 49.7962 56.4021 49.2451C55.851 48.694 55.1036 48.3844 54.3242 48.3844H42.5699C41.7906 48.3844 41.0431 48.694 40.492 49.2451C39.9409 49.7962 39.6313 50.5436 39.6313 51.323V74.8316"
        fill="#FDDB35"
      />
      <path
        d="M57.2628 74.8316V51.323C57.2628 50.5436 56.9532 49.7962 56.4021 49.2451C55.851 48.694 55.1036 48.3844 54.3242 48.3844H42.5699C41.7906 48.3844 41.0431 48.694 40.492 49.2451C39.9409 49.7962 39.6313 50.5436 39.6313 51.323V74.8316"
        stroke="black"
        strokeWidth="7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="39"
        y1="74.5"
        x2="56"
        y2="74.5"
        stroke="black"
        strokeWidth="7"
      />
      <path
        d="M22.956 143C21.9592 143 21.1722 142.599 20.5951 141.796C20.018 140.993 19.8606 140.124 20.123 139.189L34.1306 89.1778C34.3404 88.5037 34.7077 87.9727 35.2323 87.5849C35.7569 87.197 36.3602 87.0021 37.0423 87C38.0915 87 38.9047 87.4024 39.4818 88.2071C40.0589 89.0119 40.2163 89.8799 39.9539 90.8111L38.3801 96.3333H56.0863L58.0537 89.1778C58.2635 88.5037 58.6444 87.9727 59.1963 87.5849C59.7482 87.197 60.3641 87.0021 61.044 87C62.0408 87 62.8278 87.4024 63.4049 88.2071C63.982 89.0119 64.1394 89.8799 63.877 90.8111L49.8694 140.822C49.6596 141.496 49.2923 142.028 48.7677 142.418C48.2431 142.808 47.6398 143.002 46.9577 143C45.9085 143 45.0953 142.599 44.5182 141.796C43.9411 140.993 43.7837 140.124 44.0461 139.189L45.6199 133.667H27.9137L25.9463 140.822C25.7365 141.496 25.3556 142.028 24.8037 142.418C24.2518 142.808 23.6359 143.002 22.956 143ZM34.0519 111.889H51.6794L54.2763 102.556H36.6488L34.0519 111.889ZM29.7237 127.444H47.3512L49.9481 118.111H32.3206L29.7237 127.444Z"
        fill="black"
      />
    </svg>
  );
}
