import { Colors } from './theme.type';
const colorPalette: Colors = {
  black: {
    100: '#000000',
    90: '#000000E5',
    80: '#000000CC',
    70: '#000000B2',
    60: '#00000099',
    50: '#00000080',
    40: '#00000066',
    30: '#0000004D',
    20: '#00000033',
    10: '#0000001A',
  },
  white: {
    100: '#FFFFFF',
    90: '#FFFFFFE5',
    80: '#FFFFFFCC',
    70: '#FFFFFFB2',
    60: '#FFFFFF99',
    50: '#FFFFFF80',
    40: '#FFFFFF66',
    30: '#FFFFFF4D',
    20: '#FFFFFF33',
    10: '#FFFFFF1A',
  },
  grey: {
    900: '#131927',
    800: '#212936',
    700: '#394050',
    600: '#4D5461',
    500: '#6D717F',
    400: '#9EA2AE',
    300: '#D2D5DB',
    200: '#E5E7EA',
    100: '#F3F4F6',
    50: '#F9FAFB',
  },
  green: {
    900: '#152D16',
    800: '#204622',
    700: '#2B5E2E',
    600: '#36773A',
    500: '#428F46',
    400: '#4DA852',
    300: '#62B767',
    200: '#7AC27E',
    100: '#93CD96',
    50: '#ABD9AE',
  },
  orange: {
    900: '#6B3200',
    800: '#8F4300',
    700: '#B25400',
    600: '#D66400',
    500: '#FA7500',
    400: '#FF881F',
    300: '#FF9538',
    200: '#FFA557',
    100: '#FFB87A',
    50: '#FFCB9E4D',
  },
  yellow: {
    900: '#CAA802',
    800: '#E4BD02',
    700: '#FDD202',
    600: '#FDD61B',
    500: '#FDDB35',
    400: '#FEDF4E',
    300: '#FEE467',
    200: '#FEE880',
    100: '#FEED9A',
    50: '#FEF1B3',
  },
  red: {
    900: '#641D1A',
    800: '#832523',
    700: '#A9302D',
    600: '#D93E39',
    500: '#EE443F',
    400: '#F16965',
    300: '#F4827E',
    200: '#F7A9A7',
    100: '#FAC5C3',
    50: '#FDECEC',
  },
};

export default colorPalette;
