import { useTheme } from '@emotion/react';

interface ChattingIconProps {
  isActive: boolean;
}

export default function ChattingIcon(props: ChattingIconProps) {
  const { isActive } = props;

  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.879 18.472L11.337 19.388C10.854 20.204 9.647 20.204 9.163 19.388L8.621 18.472C8.201 17.762 7.991 17.406 7.653 17.21C7.315 17.013 6.89 17.006 6.04 16.991C4.784 16.97 3.997 16.893 3.337 16.619C2.73037 16.3677 2.17917 15.9994 1.71487 15.5351C1.25057 15.0708 0.882274 14.5196 0.631 13.913C0.25 12.995 0.25 11.83 0.25 9.5V8.5C0.25 5.227 0.25 3.59 0.987 2.388C1.39904 1.7152 1.96445 1.14945 2.637 0.737C3.84 0 5.478 0 8.75 0H11.75C15.023 0 16.66 0 17.863 0.737C18.5354 1.14917 19.1008 1.71456 19.513 2.387C20.25 3.59 20.25 5.228 20.25 8.5V9.5C20.25 11.83 20.25 12.995 19.87 13.913C19.6186 14.5197 19.2502 15.071 18.7857 15.5353C18.3212 15.9996 17.7698 16.3678 17.163 16.619C16.503 16.893 15.716 16.969 14.46 16.991C13.61 17.006 13.185 17.013 12.847 17.21C12.509 17.406 12.299 17.761 11.879 18.472Z"
        fill={
          isActive ? theme.colorPalette.grey[500] : theme.colorPalette.grey[300]
        }
      />
    </svg>
  );
}
